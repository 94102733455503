<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Kontoeinstellungen">
      <el-form
        ref="userForm"
        v-loading="loading"
        :model="user"
        :rules="rules"
        label-position="top"
        style="max-width: 700px;"
        @keyup.enter.native="checkForm"
      >
        <el-form-item label="E-Mail-Adresse" prop="email">
          <el-input v-model.trim="user.email"></el-input>
        </el-form-item>

        <el-form-item
          v-if="$store.getters['auth/isRegionUser']"
          label="Ihr Name"
          prop="name"
        >
          <el-input v-model="user.name"></el-input>
        </el-form-item>

        <el-form-item label="Neues Passwort wählen" prop="password">
          <el-input
            v-model="user.password"
            type="password"
            placeholder="Bitte leer lassen, falls Sie ihr Passwort nicht ändern möchten"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="Neues Passwort wiederholen"
          prop="passwordConfirmation"
        >
          <el-input
            v-model="user.passwordConfirmation"
            type="password"
            placeholder="Bitte leer lassen, falls Sie ihr Passwort nicht ändern möchten"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="$store.getters['auth/isRegionUser']"
          label="E-Mails zu Veranstaltungen erhalten"
        >
          <el-switch
            v-model="user.receive_event_emails"
            active-text="Ja"
            inactive-text="Nein"
          ></el-switch>
        </el-form-item>

        <el-form-item
          v-if="
            $store.getters['auth/isRegionAdmin'] ||
              $store.getters['auth/canManageNews']
          "
          label="E-Mails zu relevanten Neuigkeiten der letzten 7 Tage erhalten"
        >
          <el-switch
            v-model="user.receive_relevant_news_emails"
            active-text="Ja"
            inactive-text="Nein"
          ></el-switch>
        </el-form-item>

        <portal to="fixed-footer-left">
          <el-button type="primary" :loading="saving" @click="checkForm">
            Änderungen speichern
          </el-button>
        </portal>
      </el-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import FixedFooterMixin from "@/mixins/fixed_footer"
import FormMixin from "@/mixins/form.js"
import MessagesMixin from "@/mixins/messages"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"
import UserRepository from "@/repositories/user_repository.js"

export default {
  metaInfo: {
    title: "Kontoeinstellungen"
  },
  components: {
    TheBreadcrumbs,
    PageContent
  },
  mixins: [FixedFooterMixin, MessagesMixin, FormMixin, ApiErrorHandlerMixin],
  data() {
    return {
      initialEmail: null,
      loading: true,
      saving: false,
      user: {},
      rules: {
        email: [
          {
            type: "email",
            required: true,
            message: "Bitte gültige E-Mail-Adresse angeben",
            trigger: "blur"
          }
        ],
        name: [
          {
            required: true,
            message: "Bitte Ihren Namen angeben",
            trigger: "blur"
          }
        ],
        password: [
          {
            min: 10,
            message: "Das Passwort muss mind. 10 Zeichen haben",
            trigger: "change"
          }
        ],
        passwordConfirmation: [
          { validator: this.validatePasswords, trigger: "blur" },
          {
            min: 10,
            message: "Das Passwort muss mind. 10 Zeichen haben",
            trigger: "change"
          }
        ]
      }
    }
  },
  async created() {
    try {
      this.user = await UserRepository.get()
      this.initialEmail = this.user.email
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveUserSettings() {
      this.saving = true

      try {
        await UserRepository.update(this.user)
        this.saving = false
        if (this.initialEmail === this.user.email) {
          this.showFormUpdateSuccessMessage()
        } else {
          this.$message({
            message: `Die Änderungen wurden erfolgreich gespeichert. Bitte loggen Sie sich mit Ihrer neuen E-Mail-Adresse ein.`,
            type: "success",
            showClose: true
          })
          this.$store.commit("auth/logout")
          this.$router.push({ name: "LoginPage" })
        }
      } catch (error) {
        this.saving = false
        this.handleApiError(error)
      }
    },
    checkForm() {
      this.$refs["userForm"].validate(valid => {
        if (valid) {
          this.submitForm()
        } else {
          this.showFormErrors()
        }
      })
    },
    async submitForm() {
      this.saveUserSettings()
    },
    validatePasswords(rule, value, callback) {
      if (value !== this.user.password) {
        callback(new Error("Die Passwörter stimmen nicht überein"))
      } else {
        callback()
      }
    }
  }
}
</script>
